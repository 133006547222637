<template>
  <CContainer>
    <div class="loginSection">
      <CCardGroup>
        <CCard
          color="primary"
          text-color="white"
          class="text-center py-5 mb-0 loginSection-item d-md-down-none"
        >
          <h2>Truck Dashboard</h2>
          <p>{{ version }}</p>
        </CCard>
        <CCard class="loginSection-item">
          <CCardBody>
            <div class="cardLogin-img">
              <img :src="src" height="50" />
            </div>
            <CForm>
              <CInput
                v-bind:placeholder="$t('userName')"
                :autocomplete="$t('userName_email')"
                @keyup.enter.native="login"
                @input="checkUser"
                required
                :is-valid="usernameSt"
                v-model="username"
              >
                <template #prepend-content>
                  <CIcon name="cil-user" />
                </template>
              </CInput>
              <CInput
                v-bind:placeholder="$t('password')"
                type="password"
                :autocomplete="$t('current_password')"
                required
                @keyup.enter.native="login"
                @input="checkPassword"
                :is-valid="passwordSt"
                v-model="password"
              >
                <template #prepend-content>
                  <CIcon name="cil-lock-locked" />
                </template>
              </CInput>
              <CRow>
                <CCol col="12" class="mt-3">
                  <CButton
                    @click="login"
                    :disabled="inLogin"
                    color="primary"
                    class="px-5 py-2 btn-login"
                    >{{ $t("login") }}</CButton
                  >
                </CCol>
              </CRow>
              <CRow>
                <CCol col="12" class="text-center">
                  <div
                    style="
                      margin: 0px auto;
                      text-align: center;
                      margin-top: 20px;
                      color: red;
                    "
                  >
                    &nbsp;
                    <p>{{ message }}</p>
                  </div>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCardGroup>
    </div>
  </CContainer>
</template>

<script>
import AuthService from "@/services/api/auth";
import { bus } from "@/main.js";
import types from "./../ThemeJs/theme";
import Vue from "vue";

let src;
let buttonType;
types["types"].theme.map((tema, i) => {
  if (
    (tema.name == "holcim" && tema.state) ||
    (tema.name == "laFarge" && tema.state) ||
    (tema.name == "laFargeHolcim" && tema.state) ||
    (tema.name == "holcimRedAlternative" && tema.state)
  ) {
    src = tema.logoLargeLogin;
    console.log(tema.logoLargeLogin);
  }
  if (
    (tema.name == "holcimRed" && tema.state) ||
    (tema.name == "laFargeGreen" && tema.state) ||
    (tema.name == "laFargeHolcimBlue" && tema.state)
  ) {
    buttonType = "secondary";
    src = tema.logoLargeLogin;
    console.log(tema.logoLargeLogin);
  }
});

export default {
  name: "Login",
  data() {
    return {
      inheritAttrs: false,
      username: "",
      password: "",
      usernameSt: null,
      passwordSt: null,
      loading: false,
      inLogin: false,
      message: "",
      themesVue: types["types"].theme,
      buttonType: "primary",
      src: src,
      mode: Vue.prototype.$mode,
      version: Vue.prototype.$version,
    };
  },
  mounted() {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("userRol");
    var vm = this;
    setTimeout(() => {
      vm.loading = false;
    }, 5000);

    this.setTypesThemeState();
  },

  methods: {
    changeLanguage(lang) {
      this.$i18n.i18next.changeLanguage(lang);
    },

    setTypesThemeState() {
      var e;
      var themes = localStorage.theme;
      if (themes.length == 0 || themes == null) {
        e = "laFargeHolcimblueAlternative";
      } else {
        e = themes;
      }

      this.themesVue.map((tema, i) => {
        if (tema.name == e) {
          tema.state = true;
        } else {
          tema.state = false;
        }
      });
      this.themesVue.map((tema, i) => {
        if (
          (e == "holcim" && tema.state) ||
          (e == "laFarge" && tema.state) ||
          (e == "laFargeHolcim" && tema.state) ||
          (e == "holcimRedAlternative" && tema.state) ||
          (e == "laFargeGreenAlternative" && tema.state) ||
          (e == "laFargeHolcimblueAlternative" && tema.state)
        ) {
          console.log(tema.logoLargeLogin);
          this.buttonType = "primary";
          this.src = tema.logoLargeLogin;
          bus.$emit("tipoBotton", this.buttonType);
          bus.$emit("tema", tema);
        }
        if (
          (e == "holcimRed" && tema.state) ||
          (e == "laFargeGreen" && tema.state) ||
          (e == "laFargeHolcimBlue" && tema.state)
        ) {
          console.log(tema.logoLargeLogin);
          this.src = tema.logoLargeLogin;
          this.buttonType = "secondary";
          bus.$emit("tipoBotton", this.buttonType);
          bus.$emit("tema", tema);
        }
      });
      types.selectTheme(types.types.theme);
    },

    login() {
      var vm = this;
      let go = true;

      if (vm.username == "") {
        go = false;
        vm.usernameSt = false;
      } else vm.usernameSt = true;

      if (vm.password == "") {
        go = false;
        vm.passwordSt = false;
      } else vm.passwordSt = true;

      vm.inLogin = true;

      if (!go) vm.inLogin = false;

      if (go) {
        vm.inLogin = true;
        this.loading = true;

        AuthService.login(vm.username, vm.password, vm.plantsOptionSelected)
          .then((response) => {
            vm.inLogin = false;
            this.loading = false;

            sessionStorage.setItem("user", JSON.stringify({token: response.data.access_token}));
            location.href = "#/dashboard"//?t" + new Date().getMilliseconds();
            
          })
          .catch((e) => {
            console.log("ERROR CATCH: %O", e);
            vm.inLogin = false;
            this.loading = false;
            vm.message = e;
            console.log(this.$i18n.t("login_error") + e);
          });
      }
    },
    checkUser() {
      var vm = this;
      if (vm.username == "") vm.usernameSt = false;
      else vm.usernameSt = true;
    },

    checkPassword() {
      var vm = this;

      if (vm.password == "") vm.passwordSt = false;
      else vm.passwordSt = true;
    },

    setLanguageUser(text, user) {
      for (const key in text) {
        // eslint-disable-next-line no-prototype-builtins
        if (user.text.hasOwnProperty(key)) {
          const element = user.text[key];
          this.$i18n.i18next.addResourceBundle(key, "translation", element);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.loginSection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loginSection-item {
  min-width: 300px;
}
.cardLogin-img {
  text-align: center;
  margin-bottom: 2em;
}
.btn-login {
  width: 100%;
}
</style>